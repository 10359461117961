.review-container {
    background-color: var(--green);
    width: 100%
}

.reviews {
    overflow-x: hidden;
    padding-top: 250px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
}

.inner-reviews {
    display: flex;
    align-items: center;
    gap: 50px;
}

.reviews-button {
    display: flex;
    justify-content: center;
    padding: 50px;
    padding-bottom: 100px;
}
