/* Worksection */
.work-container {
    background-color: var(--beige);
    margin: auto auto;
    display: grid;
    max-width: 100%;
    height: fit-content;
    justify-content: center;
}

/* work images and grid */
.work-group {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 2em;
    grid-template-rows: auto auto;
    padding: 2em;
    max-width: 100%;
}

.work-group.mob {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.work-card {
    background-color: var(--white);
    border: 2px solid var(--black);
    box-shadow: 5px 5px var(--black);
    border-radius: 10px;
    width: 25vw;
    height: 50em;
    overflow: hidden
}

.work-card.mob {
    width: 20vw
}

.work-card.games-dashboards {
    height: 300px;
}

.work-card-image {
    width: 100%;
    height: 100%;
    /* transform: translateY(0px);  */
    /* transition: all  ease; */
    object-fit: cover;
    object-position: top center;
    /* transition: all 15s; */
    transition: all 15s;
}

.work-card-image:hover {
    /* transform: translateY(calc(-100% + 800px)); */
    object-position: bottom center;
}

@media (max-width: 1200px) {
    .work-card {
        width: 80vw;
        height: 400px;
    }

    .work-card-image:hover {
        /* transform: translateY(calc(-100% + 400px)); */
        object-position: bottom center;
    }

    .work-card.games-dashboards {
        height: 300px;
    }

    .work-card.mob {
        width: 80vw
    }

    .work-group {
        grid-template-columns: 1fr;
    }

    .work-group.mob {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .work-card {
        height: 250px;
    }
    
    .work-card.games-dashboards {
        height: 250px;
    }

    .work-card-image:hover {
        /* transform: translateY(calc(-100% + 250px)); */
        object-position: bottom center;
    }
}

/* work button */
.work-button {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 50px;
}



/* Tab css */
.tab-group {
    justify-content: center;
    display: flex;
}

.tab-row {
    display: flex;
    gap: 100px;
    padding: 100px;
    justify-content: center;
    align-items: center;
}

.tab-item {
    font-size: small;
    cursor: url(../../static/svg/cursorClick.svg), auto;
}

.tab-item.active {
    background-color: var(--yellow);
    color: var(--black);
    border-radius: 10px;
    box-shadow: 5px 5px var(--black);
    border: 2px solid var(--black);
    padding: 20px 0px;
}

@media (max-width: 1200px) {
    .tab-row {
        display: none;
    }
}

/* Dropdown css */
.dropdown {
    background-color: var(--green);
    color: var(--black);
    padding: 10px;
    border: 2px solid var(--black);
    border-radius: 10px;
    box-shadow: 5px 5px var(--black);
    margin: 20px;
    width: 80%;
}

.Dropdown-control.dropdown-control {
    background-color: var(--green);
    border: none;
    color: var(--black);
    padding: 5px;
    font-size: small;
    align-items: center;
    display: flex;
}

.Dropdown-arrow.dropdown-arrow {
    border-color: var(--black) transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    content: ' ';
    display: block;
    height: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 0;
}

.Dropdown-placeholder.dropdown-placeholder {
    margin-right: auto;
}

.Dropdown-menu.dropdown-menu {
    background-color: var(--white);
    border-radius: 10px;
    border: 2px solid var(--black);
    box-shadow: 5px 5px var(--black);
    font-size: small;
    margin: 5px auto 0px auto;
    top: 100%;
}

@media (min-width: 1200px) {
    .dropdown {
        display: none;
    }
}