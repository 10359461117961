.hero-container {
    background-color: var(--purple);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px 20px;
    gap: 20px;
    overflow: hidden;
}

.cta-button {
    padding-left: 10px;
}
.text-white {
    color: var(--white);
    font-family: "MonumentExtended", sans-serif;
}

.btn {
    background-color: var(--purple);
    height: 50px;
    width: 200px;
    color: var(--black);
    border-radius: 10px;
}

.btn.green {
    background-color: var(--green);
}

.btn.bs-black {
    box-shadow: 5px 5px var(--black);
}

.btn.yellow {
    background-color: var(--yellow);
}

.btn.beige {
    background-color: var(--beige);
}

.webpage {
    color: inherit;
    border: 2px solid var(--black);
    border-radius: 5px;
    max-height: 600px;
    max-width: 480px;
    box-shadow: 10px 10px var(--black);
    padding: 10px;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: var(--yellow);
    border-radius: 50%;
    border: 2px solid var(--black);
    display: inline-block;
    margin: 10px 5px 10px 5px;
}

.option-row {
    display: flex-start;
    align-items: center;
}

.url-bar {
    color: inherit;
    border: 2px solid var(--black);
    border-radius: 5px;
    color: var(--black);
    font-size: small;
    padding: 20px;
}

.top-play-area {
    display: flex;
    padding: 10px;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.top-play-area > * {
    width: 50%;
    height: 100%;
}

.top-play-area-cta > * {
    max-width: 100%;
    height: 30%;
}

.top-play-area-cta:nth-child(2){
    width: 100%;
}

.top-play-area-image {
    width: 100%;
    height: 100%;
}

.bottom-play-area {
    display: flex;
    justify-content: space-between;
}

.bottom-play-area > * {
    width: 33%;
    height: 100%;
}


@media (max-width: 1200px) {
    .hero-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

    }

    /* .webpage {
        width: 90%;
        height: fit-content;
        padding: 10px;
    } */
}
