.contact {
    background-color: var(--pink);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 20px 0px 20px;
    z-index: 1;
}
.submit-button {
    margin-bottom: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.contact-form {
    border: 2px solid var(--black);
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;;
    border-radius: 10px;
    background-color: var(--white);
    padding: 20px;
    padding-right: 25px;
    margin: auto;
    width: 80%;
    z-index: 1;
    justify-content: center;
}

.envelope {
    position: relative;
    width: 100%;
    height: 200px;
    bottom: 20px;
}

.envelope.top {
    position: absolute;
    z-index: 2;
}

.envelope.bottom {
    position: absolute;
    z-index: 0;
    bottom: 78px;
}

.form-element > label {
    display: block;
    font-size: small;
    margin-bottom: 10px;
}

.form-element > input {
    width: 100%;
    border: 2px solid var(--black);
    border-radius: 5px;
    margin-bottom: 20px;
    font-family: "Space Grotesk", sans-serif;
    height: 40px;
}

.form-element > input:required {
    border: 2px solid red;
}


.form-element > textarea {
    width: 100%;
    border: 2px solid var(--black);
    border-radius: 5px;
    margin-bottom: 20px;
    font-family: "Space Grotesk", sans-serif;
}


@media (min-width: 760px) {
    .contact-form {
        width: 400px;
        min-width: 400px
    }

    .envelope {
        width: 600px;
        left: 0px;
    }
}


@media (min-width: 1200px) {

    .contact-form {
        width: 600px;
        min-width: 600px;
    }

    .envelope {
        width: 800px;
        left: 0px;
    }

    .form-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }
}