.navbar {
    background-color: var(--white);
    height: 110px;
    width: 100%;
    color: var(--black);
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: medium solid var(--black);
}

.logo {
    align-self: center;
}

.nav-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 70px
}

.nav-contact-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

@media (max-width: 768px) {
    .nav-group {
        display: none;
    }
}
