:root {
    --black: #1A1A1A;
    --purple: #C9A4FF;
    --green: #A4D677;
    --yellow: #F8D457;
    --beige: #FDF8EA;
    --pink: #FEB3EB;
    --blue: #7894FF;
    --white: #FFFFFF;
}

.loading-spinner {
    position: absolute;
    top: 50%;
    right: 50%;
}