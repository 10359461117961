.about{
    position: relative;
}

.about-section {
    background-color: var(--yellow);
    display: flex;
    flex-direction: column;
    color: var(--black);
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-x: hidden;
    padding: 0px 20px;
    padding-bottom: 64px;
}

.burst-image {
    position: sticky;
    margin-top: -70px;
    height: 450px;
    width: 450px;
    align-self: flex-start;
}

.circle-image {
    width: 450px;
    height: 450px;
    background-image: url(../../static/img/shweta.png);
    background-repeat: no-repeat;
    background-size: 450px 450px;
    border-radius: 50%;
    align-self: center;
    justify-self: center;
    align-self: flex-end;
}

.skewed-tape-wrapper {
    overflow-x: hidden;
    display: block;
}

.about-text {
    font-family: "MonumentExtended", sans-serif;
    font-size: 2.5em;
}

.about-row {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    width: fit-content;
    gap: 50px;
}

.about-row:nth-child(2) {
    flex-direction: row-reverse;
}

.skewed-marquee-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

@media (max-width: 1200px){
    .about-row {
        flex-direction: column;
        gap: 20px;
    }

    .burst-image {
        width: 300px;
        height: 300px;
        background-size: 300px 300px;
        margin-top: -40px;
    }

    .circle-image {
        width: 300px;
        height: 300px;
    }

    .about-row:nth-child(2) {
        flex-direction: column;
        gap: 20px;
    }

    .circle-image {
        margin-right: -50px;
    }

    .about-text {
        font-size: 1.9em;
    }
}