.config-container {
    height: 100vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 4fr;
    max-width: 1600px;
}

.side-bar {
    border-right: 2px solid var(--black);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    padding-top: 50px;
}

.main-content {
    padding: 20px;
}

.config-element {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.config-element > input {
    border: 2px solid var(--black);
    border-radius: 5px;
    margin-bottom: 20px;
    font-family: "Space Grotesk", sans-serif;
    height: 40px;
}

.add-new-container {
    display: flex;
    justify-content: space-between;
}

.config-table {
    padding: 20px;
    border: 2px solid var(--black);
    border-radius: 5px;
    box-shadow: 5px 5px var(--black);
    max-height: 800px;
}

.button-group {
    display: flex;
    gap: 20px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.close-button {
    display: flex;
    padding: 5px 20px;
    align-items: center;
    gap: 5px;
}

tr > td {
    justify-content: center;
}