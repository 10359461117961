@font-face {
    font-family: "MonumentExtended";
    src: url(static/fonts/MonumentExtended-Regular.otf);
    -webkit-font-smoothing: antialiased;
}


html {
    scroll-behavior: smooth;
    cursor: url(./static/svg/cursor.svg), auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 600;
  text-rendering: optimizeLegibility; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  cursor: url(./static/svg/cursor.svg), auto;
}

a {
    all: unset;
}

div {
    cursor: url(./static/svg/cursor.svg), auto;
}

button {
    cursor: url(./static/svg/cursorClick.svg), auto;
}

label {
    cursor: url(./static/svg/cursor.svg), auto;
}

button > * {
    cursor: url(./static/svg/cursorClick.svg), auto;
}

input {
    cursor: url(./static/svg/inputCursor.svg), auto;
}

input > * {
    cursor: url(./static/svg/inputCursor.svg), auto;
}

textarea {
    cursor: url(./static/svg/inputCursor.svg), auto;
}

textarea > * {
    cursor: url(./static/svg/inputCursor.svg), auto;
}
