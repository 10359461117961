.display {
    font-size: 5em;
    margin: 10px;
    font-family: "MonumentExtended", sans-serif;
}

.tape-text {
    font-size: 6em;
    font-family: "MonumentExtended", sans-serif;
    margin: 20px;
}


.highlight-text {
    font-family: "MonumentExtended", sans-serif;
}

.skewed-tape-wrapper-footer {
    margin-top: -80px;
}
.skewed-tape-text {
    font-family: "MonumentExtended", sans-serif;
    margin-left: 15px;
    margin-right: 15px;
}

.skewed-tape-img {
    height: 30px;
    width: 30px;
    margin-right: 15px;
    margin-left: 15px
}

.buttonText {
    margin: 0;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 600;
    cursor: url(../../static/svg/cursorClick.svg), auto;
}

.tape-item {
    margin-left: 25px;
    margin-right: 25px
}

@media (max-width: 1200px) {
    .display {
        font-size: 3em;
        font-family: "MonumentExtended", sans-serif;
    }

    .tape-text {
        font-size: 5em;
    }
}